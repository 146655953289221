<template>
  <projects :stage="enums.PROJECT_STAGE.Opportunity"></projects>
</template>

<script>
import Projects from "../components/Projects.vue";
import Enums from "../../../plugins/enums";

export default {
  data() {
    return {
      enums: Enums,
    };
  },
  components: { Projects },
};
</script>
